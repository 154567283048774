import { FormLayer } from './form-layer.js';
import { computed } from '@angular/core';
class FormRoot extends FormLayer {
  //</editor-fold>
  constructor(controls, disabledDefaultValue, disabledByDefault = false, errorValidators = [], warningValidators = []) {
    super(controls, false, disabledDefaultValue, disabledByDefault, errorValidators, warningValidators);
    //<editor-fold desc="Form State">
    this.canUpdate = computed(() => this.valid() && this.changed());
    this.canCreate = computed(() => this.valid());
  }
}
export { FormRoot };