import { isFunction, isBool } from '@juulsgaard/ts-tools';
import { FormPage } from './form-page.js';
import { signal } from '@angular/core';
class FormPageConfig {
  constructor(type, controls) {
    this.type = type;
    this.controls = controls;
    this.errorValidators = [];
    this.warningValidators = [];
    this.submitBtnText = type === "create" ? "Create" : "Save";
    this.deleteBtnText = "Delete";
  }
  provideConfirmService(service) {
    this.warningService = service;
    return this;
  }
  provideInjector(injector) {
    this.injector = injector;
    return this;
  }
  withSubmitWarning(title, text, btnText) {
    const getTitle = isFunction(title) ? title : () => title;
    const getText = isFunction(text) ? text : () => text;
    this.submitWarning = val => ({
      title: getTitle(val),
      text: getText(val),
      btnText
    });
    return this;
  }
  withDeleteWarning(title, text, btnText) {
    const getTitle = isFunction(title) ? title : () => title;
    const getText = isFunction(text) ? text : () => text;
    this.deleteWarning = val => ({
      title: getTitle(val),
      text: getText(val),
      btnText
    });
    return this;
  }
  limitSubmit(canSubmit) {
    this.canSubmit = isBool(canSubmit) ? signal(canSubmit) : canSubmit;
    return this;
  }
  limitDelete(canDelete) {
    this.canDelete = isBool(canDelete) ? signal(canDelete) : canDelete;
    return this;
  }
  withSubmit(action, btnText) {
    this.onSubmit = action;
    if (btnText) this.submitBtnText = btnText;
    return this;
  }
  withDelete(action, btnText) {
    this.onDelete = action;
    if (btnText) this.deleteBtnText = btnText;
    return this;
  }
  /**
   * Add validators to the layer
   * @param validators
   */
  withErrors(...validators) {
    this.errorValidators = [...this.errorValidators, ...validators];
    return this;
  }
  /**
   * Add warning validators to the layer
   * @param validators
   */
  withWarnings(...validators) {
    this.warningValidators = [...this.warningValidators, ...validators];
    return this;
  }
  getOptions() {
    return {
      onSubmit: this.onSubmit,
      submitBtnText: this.submitBtnText,
      submitWarning: this.submitWarning,
      canSubmit: this.canSubmit,
      onDelete: this.onDelete,
      deleteBtnText: this.deleteBtnText,
      deleteWarning: this.deleteWarning,
      canDelete: this.canDelete,
      errorValidators: this.errorValidators,
      warningValidators: this.warningValidators,
      warningService: this.warningService,
      injector: this.injector
    };
  }
  done() {
    return new FormPage(this.type, this.controls, this.getOptions());
  }
}
export { FormPageConfig };