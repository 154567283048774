import '@angular/core';
import 'rxjs';
import '@juulsgaard/ts-tools';
import '../forms/form-node.js';
import { toList } from '../tools/helpers.js';
import { FormList } from '../forms/form-list.js';
import { formLayer } from './form-layer-constructors.js';
class FormListConstructors {
  constructor() {
    this.nullable = new FormListNullableConstructors();
  }
  /**
   * Create a Form List based on controls
   * @param controls - The controls to use
   * @param options - Additional options
   * @constructor
   */
  controls(controls, options) {
    return new FormList(formLayer.controls(controls, options?.layer), false, options?.length, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
  /**
   * Create a Form List based on an element type
   * @param controls - The controls to use
   * @param options - Additional options
   * @constructor
   */
  model(controls, options) {
    return new FormList(formLayer.model(controls, options?.layer), false, options?.length, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
}
class FormListNullableConstructors {
  /**
   * Create a Form List based on controls
   * @param controls - The controls to use
   * @param options - Additional options
   * @constructor
   */
  controls(controls, options) {
    return new FormList(formLayer.controls(controls, options?.layer), true, options?.length, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
  /**
   * Create a Form List based on an element type
   * @param controls - The controls to use
   * @param options - Additional options
   * @constructor
   */
  model(controls, options) {
    return new FormList(formLayer.model(controls, options?.layer), true, options?.length, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
}
const formList = new FormListConstructors();
export { FormListConstructors, FormListNullableConstructors, formList };