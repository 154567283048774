import { isFunction } from '@juulsgaard/ts-tools';
import { Subject, switchMap, startWith } from 'rxjs';
import { Loading, ILoadingState } from '@juulsgaard/rxjs-tools';
import { computed, signal, isSignal, inject, assertInInjectionContext, effect, DestroyRef, untracked } from '@angular/core';
import '../tools/constants.js';
import { willAlterForm } from '../tools/form-population.js';
import '../forms/form-node.js';
import { FormConfirmService } from './form-confirm.service.js';
import { formRoot } from '../constructors/form-root-constructors.js';
import { toSignal } from '@angular/core/rxjs-interop';
class BaseFormPage {
  //</editor-fold>
  constructor(type, options) {
    this._submitting$ = new Subject();
    this._deleting$ = new Subject();
    this.warningService = options.warningService;
    this.onSubmit = options.onSubmit;
    this.submitBtnText = options.submitBtnText;
    this.submitWarning = options.submitWarning;
    this.onDelete = options.onDelete;
    this.deleteBtnText = options.deleteBtnText;
    this.deleteWarning = options.deleteWarning;
    this.hasSubmit = !!this.onSubmit;
    this.canSubmit = computed(() => {
      if (!this.hasSubmit) return false;
      return type === "update" ? this.form.canUpdate() : this.form.canCreate();
    });
    this.showSubmit = !this.hasSubmit ? signal(false) : options.canSubmit == null ? signal(true) : isSignal(options.canSubmit) ? options.canSubmit : toSignal(options.canSubmit, {
      injector: this.requireInjector(),
      initialValue: false
    });
    this.hasDelete = !!options.onDelete;
    this.showDelete = !this.hasDelete ? signal(false) : options.canDelete == null ? signal(true) : isSignal(options.canDelete) ? options.canDelete : toSignal(options.canDelete, {
      injector: this.requireInjector(),
      initialValue: false
    });
    this.submitting = toSignal(this._submitting$.pipe(switchMap(x => x.loading$)), {
      initialValue: false,
      manualCleanup: true
    });
    this.submitError = toSignal(this._submitting$.pipe(switchMap(x => x.error$.pipe(startWith(void 0)))), {
      initialValue: void 0,
      manualCleanup: true
    });
    this.deleting = toSignal(this._deleting$.pipe(switchMap(x => x.loading$)), {
      initialValue: false,
      manualCleanup: true
    });
    this.deleteError = toSignal(this._deleting$.pipe(switchMap(x => x.error$.pipe(startWith(void 0)))), {
      initialValue: void 0,
      manualCleanup: true
    });
    if (this.submitWarning || this.deleteWarning) {
      if (!this.warningService) {
        this.warningService = this.requireInjector()?.get(FormConfirmService) ?? inject(FormConfirmService);
      }
    }
  }
  requireInjector() {
    if (this.injector) return this.injector;
    assertInInjectionContext(BaseFormPage);
    return void 0;
  }
  update(value) {
    const changed = willAlterForm(this.form, value);
    if (!changed) return;
    this.form.reset(value);
  }
  updateFrom(values, options) {
    if (isFunction(values)) {
      if (!options?.injector) assertInInjectionContext(this.updateFrom);
      return effect(() => this.update(values()), {
        allowSignalWrites: true,
        injector: options?.injector,
        manualCleanup: options?.manualCleanup
      });
    }
    if (options?.manualCleanup && !options?.injector) assertInInjectionContext(this.updateFrom);
    const sub = values.subscribe({
      next: v => this.update(v)
    });
    if (options?.manualCleanup) {
      const destroy = options.injector?.get(DestroyRef) ?? inject(DestroyRef);
      destroy.onDestroy(() => sub.unsubscribe());
    }
    return sub;
  }
  //<editor-fold desc="Submit">
  submit() {
    if (!this.showSubmit()) return Loading.Empty();
    if (!this.canSubmit()) return Loading.Empty();
    if (this.submitting()) return Loading.Empty();
    if (!this.form.isValid()) return Loading.Empty();
    return Loading.Async(this.submitAsync());
  }
  async submitAsync() {
    const value = this.form.getValidValue();
    const shouldSubmit = await this.shouldSubmit(value);
    if (!shouldSubmit) return;
    const submit = this.onSubmit;
    const state = execute(() => submit(value));
    this._submitting$.next(state);
    await state;
  }
  async shouldSubmit(value) {
    if (!this.submitWarning) return true;
    if (!this.warningService) {
      console.error("Could not render submission warning");
      throw Error("Could not render submission warning");
    }
    const warning = this.submitWarning(value);
    return await this.warningService.confirmSubmit(warning);
  }
  //</editor-fold>
  //<editor-fold desc="Delete">
  delete() {
    if (!this.showDelete()) return Loading.Empty();
    if (this.deleting()) return Loading.Empty();
    return Loading.Async(this.deleteAsync());
  }
  async deleteAsync() {
    const value = untracked(this.form.value);
    const shouldDelete = await this.shouldDelete(value);
    if (!shouldDelete) return;
    const doDelete = this.onDelete;
    const state = execute(() => doDelete(value));
    this._deleting$.next(state);
    await state;
  }
  async shouldDelete(value) {
    if (!this.deleteWarning) return true;
    if (!this.warningService) {
      console.error("Could not render deletion warning");
      throw Error("Could not render deletion warning");
    }
    const warning = this.deleteWarning(value);
    return await this.warningService.confirmDelete(warning);
  }
  //</editor-fold>
}
class FormPage extends BaseFormPage {
  constructor(type, controls, options) {
    super(type, options);
    this.form = formRoot.model(controls, {
      errors: options.errorValidators,
      warnings: options.warningValidators
    });
    this.controls = this.form.controls;
    this.value = this.form.value;
  }
}
function execute(action) {
  try {
    const result = action();
    if (result instanceof ILoadingState) return result;
    if (!result) return Loading.Empty();
    return Loading.Async(result);
  } catch (e) {
    const error = e instanceof Error ? e : Error(e?.toString());
    return Loading.FromError(() => error);
  }
}
export { BaseFormPage, FormPage };