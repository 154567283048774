import { computed, signal } from '@angular/core';
import { Subject } from 'rxjs';
class FormUnit {
  constructor(nullable, disabled) {
    this.nullable = nullable;
    this._reset$ = new Subject();
    /** An event emitted when the input resets */
    this.reset$ = this._reset$.asObservable();
    /** A single warning for the unit. undefined if no warnings are present (async) */
    this.warning = computed(() => this.warnings().at(0));
    /** A boolean indicating if the unit has an error (async) */
    this.hasError = computed(() => this.errors().length > 0);
    /** A single error for the unit. undefined if no errors are present (async) */
    this.error = computed(() => this.errors().at(0));
    this.pristine = computed(() => !this.changed() && !this.touched());
    this._disabled = signal(disabled);
    this.disabled = this._disabled.asReadonly();
  }
  reset() {
    this._reset$.next();
  }
  toggleTouched(touched) {
    if (this.touched()) {
      if (touched === true) return false;
      this.markAsUntouched();
      return true;
    }
    if (touched === false) return false;
    this.markAsTouched();
    return true;
  }
  //</editor-fold>
  //<editor-fold desc="Disabled">
  disable() {
    this._disabled.set(true);
  }
  enable() {
    this._disabled.set(false);
  }
  toggleDisabled(disable) {
    if (this.disabled()) {
      if (disable === true) return false;
      this.enable();
      return true;
    }
    if (disable === false) return false;
    this.disable();
    return true;
  }
}
export { FormUnit };