const EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
class FormValidators {
  patternValidator(regex, error) {
    return value => {
      if (!value) return null;
      const valid = regex.test(value);
      return valid ? null : error;
    };
  }
  hexColor(error) {
    return this.patternValidator(/^#[\da-f]{6}([\da-f]{2})?$/i, error ?? "Invalid hex format");
  }
  url(allowHttp = false, error) {
    return value => {
      if (!value) return null;
      const match = value.match(/^([a-z][-.+a-z\d]*):\/\//i);
      if (!match || match[1] === "https") {
        const valid2 = value.match(/^(https:\/\/)?[-a-z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-z0-9@:%_+.~#?&/=]*)?$/i);
        if (!valid2) return error ?? "Invalid Website URL";
        return null;
      }
      if (!allowHttp && match[1] === "http") {
        return error ?? "http URLs are not supported";
      }
      const valid = value.match(/^([a-z][-.+a-z\d]*):\/\/[-a-z0-9@:%_+.~#?&/=]*$/i);
      if (!valid) return error ?? "Invalid URL";
      return null;
    };
  }
  guid(error) {
    return this.patternValidator(/^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i, error ?? "Invalid GUID");
  }
  email(error) {
    return this.patternValidator(EMAIL_REGEXP, error ?? "Invalid Email");
  }
  min(min, error) {
    return value => {
      if (value == null) return null;
      value = Number(value);
      if (Number.isNaN(value)) return null;
      if (value >= min) return null;
      return error ?? `The minimum value is ${min}`;
    };
  }
  max(max, error) {
    return value => {
      if (value == null) return null;
      value = Number(value);
      if (Number.isNaN(value)) return null;
      if (value <= max) return null;
      return error ?? `The maximum value is ${max}`;
    };
  }
  minLength(minLength, error) {
    return value => {
      if (value == null) return null;
      const length = value.toString().length;
      if (length >= minLength) return null;
      return error ?? `A minimum length of ${minLength} is required [${length}/${minLength}]`;
    };
  }
  maxLength(maxLength, error) {
    return value => {
      if (value == null) return null;
      const length = value.toString().length;
      if (length <= maxLength) return null;
      return error ?? `Maximum length of ${maxLength} ${length}/${maxLength}]`;
    };
  }
}
const Validators = new FormValidators();
export { FormValidators, Validators };