import { FormUnit } from './form-unit.js';
import { Subject } from 'rxjs';
import { computed } from '@angular/core';
var InputEvents = /* @__PURE__ */(InputEvents2 => {
  InputEvents2["Focus"] = "focus";
  InputEvents2["Select"] = "select";
  InputEvents2["ScrollTo"] = "scroll-to";
  return InputEvents2;
})(InputEvents || {});
var InputTypes = /* @__PURE__ */(InputTypes2 => {
  InputTypes2["Generic"] = "generic";
  InputTypes2["Text"] = "text";
  InputTypes2["Url"] = "url";
  InputTypes2["Number"] = "number";
  InputTypes2["Password"] = "password";
  InputTypes2["Bool"] = "boolean";
  InputTypes2["Date"] = "text-date";
  InputTypes2["DateTime"] = "datetime-local";
  InputTypes2["Time"] = "time";
  InputTypes2["Color"] = "color";
  InputTypes2["Email"] = "email";
  InputTypes2["Phone"] = "tel";
  InputTypes2["LongText"] = "textarea";
  InputTypes2["HTML"] = "html";
  InputTypes2["Select"] = "select";
  InputTypes2["SelectMany"] = "multipleSelect";
  InputTypes2["Search"] = "search";
  InputTypes2["File"] = "file";
  return InputTypes2;
})(InputTypes || {});
class AnonFormNode extends FormUnit {
  constructor(type, defaultValue, initialValue, nullable, options) {
    super(nullable, !!options?.disabled);
    this.type = type;
    this.defaultValue = defaultValue;
    this.initialValue = initialValue;
    this.options = options;
    this._actions$ = new Subject();
    /** An observable emitting input actions */
    this.actions$ = this._actions$.asObservable();
    this.empty = computed(() => this.state() == null);
    this.required = !!options?.required;
    if (!options) return;
    this.label = options.label;
    this.autocomplete = options.autocomplete;
    this.tooltip = options.tooltip;
    this.readonly = options.readonly;
    this.autoFocus = options.autoFocus;
    this.showDisabledField = options.showDisabledField;
  }
  /**
   * Focus the input
   * @param options - Additional options
   */
  focus(options) {
    setTimeout(() => {
      this._actions$.next("focus" /* Focus */);
      if (options?.selectValue) this._actions$.next("select" /* Select */);
      if (options?.scroll) this._actions$.next("scroll-to" /* ScrollTo */);
    }, 0);
  }
  scrollTo() {
    this._actions$.next("scroll-to" /* ScrollTo */);
  }
}
export { AnonFormNode, InputEvents, InputTypes };