import { FormDialog } from './form-dialog.js';
class FormDialogConfig {
  constructor(type, controls) {
    this.type = type;
    this.controls = controls;
    this.errorValidators = [];
    this.warningValidators = [];
  }
  /**
   * Add validators to the form
   * @param validators
   */
  withErrors(...validators) {
    this.errorValidators = [...this.errorValidators, ...validators];
    return this;
  }
  /**
   * Add warning validators to the form
   * @param validators
   */
  withWarnings(...validators) {
    this.warningValidators = [...this.warningValidators, ...validators];
    return this;
  }
  /**
   * Toggle whether the form should submit when the user hits enter
   * @param submitOnEnter
   */
  submitOnEnter(submitOnEnter) {
    this.shouldSubmitOnEnter = submitOnEnter;
    return this;
  }
  /**
   * Set custom submit button text
   * @param buttonText
   */
  withButtonText(buttonText) {
    this.buttonText = buttonText;
    return this;
  }
  /**
   * Configure the dialog
   * @param settings
   */
  configure(settings) {
    return new FormDialog(this.controls, this.type, settings, this.shouldSubmitOnEnter, this.buttonText, this.errorValidators, this.warningValidators);
  }
}
export { FormDialogConfig };