import { FormUnit } from './form-unit.js';
import { computed } from '@angular/core';
import { FormNode } from './form-node.js';
class AnonFormLayer extends FormUnit {
  constructor() {
    super(...arguments);
    /** A list of all the Form Nodes in the layer */
    this.nodes = computed(() => Object.values(this.controls()).filter(x => x instanceof FormNode));
  }
}
export { AnonFormLayer };