import { deepEquals } from '@juulsgaard/ts-tools';
import { untracked } from '@angular/core';
function willAlterForm(form, newData) {
  return untracked(() => {
    const oldData = form.resetValue();
    if (oldData == null) return newData != null;
    if (newData == null) return true;
    if ("id" in oldData) {
      if (!("id" in newData)) return true;
      if (oldData["id"] !== newData["id"]) return true;
    }
    const copy = form.clone();
    copy.reset(newData);
    return !deepEquals(oldData, copy.resetValue());
  });
}
export { willAlterForm };