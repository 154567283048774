import { isString } from '@juulsgaard/ts-tools';
function compareLists(prev, next, comparison) {
  if (prev === next) return true;
  if (prev == null) return next == null;
  if (next == null) return false;
  if (prev.length != next.length) return false;
  if (prev.length <= 0) return true;
  comparison ?? (comparison = (a, b) => a === b);
  for (let i = 0; i < prev.length; i++) {
    if (!comparison(prev[i], next[i])) return false;
  }
  return true;
}
function compareValues(prev, next) {
  if (prev === next) return true;
  if (prev == null) return next == null;
  if (next == null) return false;
  if (Array.isArray(prev)) {
    if (!Array.isArray(next)) return false;
    return compareLists(prev, next, compareValues);
  }
  if (prev instanceof Date) {
    if (!(next instanceof Date)) return false;
    return prev.getTime() === next.getTime();
  }
  return false;
}
function toList(data) {
  if (data == null) return [];
  if (Array.isArray(data)) return data;
  return [data];
}
const reservedFunProps = /* @__PURE__ */new Set(["name", "length", "caller", "arguments", "prototype"]);
function formatFuncProp(key) {
  if (!isString(key)) return key;
  if (!reservedFunProps.has(key)) return key;
  return `$${key}`;
}
export { compareLists, compareValues, formatFuncProp, toList };