import { isObject } from '@juulsgaard/ts-tools';
import { isFormNode, isFormLayer, isFormList } from './type-predicates.js';
import { formList } from '../constructors/form-list-constructors.js';
import { formLayer } from '../constructors/form-layer-constructors.js';
import { BaseFormLayerConfig } from '../forms/form-layer-config.js';
import { BaseFormNodeConfig } from '../forms/form-node-config.js';
import { BaseFormListConfig } from '../forms/form-list-config.js';
function formTemplateToControls(template) {
  return templateLayerToControls(template);
}
function formTemplateToValueControls(template) {
  return formTemplateToControls(templateUnionToTemplate(template));
}
function templateUnionToTemplate(union) {
  return union;
}
function templateLayerToControls(template) {
  const result = {};
  for (let key in template) {
    const sub = template[key];
    if (!sub) continue;
    const unit = templateToControl(sub);
    if (!unit) continue;
    result[key] = unit;
  }
  return result;
}
function templateToControl(template) {
  if (template instanceof BaseFormNodeConfig) return template.done();
  if (isFormNode(template)) return template;
  if (template instanceof BaseFormLayerConfig) return template.done();
  if (isFormLayer(template)) return template;
  if (template instanceof BaseFormListConfig) return template.done();
  if (isFormList(template)) return template;
  if (Array.isArray(template)) {
    const tmp = template;
    const subTemplate = tmp[0];
    return formList.controls(templateLayerToControls(subTemplate));
  }
  if (isObject(template)) {
    return formLayer.model(templateLayerToControls(template));
  }
  return void 0;
}
export { formTemplateToControls, formTemplateToValueControls };