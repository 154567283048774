import { FormUnit } from './form-unit.js';
import { computed } from '@angular/core';
class AnonFormList extends FormUnit {
  constructor() {
    super(...arguments);
    /** The current amount of controls */
    this.length = computed(() => this.controls().length);
    /** True if the list has no controls */
    this.empty = computed(() => this.length() <= 0);
  }
}
export { AnonFormList };