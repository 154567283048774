import { FormDialogFactory } from './form-dialog-factory.js';
class FormDialogConstructors {
  /**
   * Create a new creation Form Dialog.
   * Use this when the dialog is used for creating something.
   * @constructor
   */
  create() {
    return new FormDialogFactory("create");
  }
  /**
   * Create a new update Form Dialog.
   * Use this when the dialog is modifying data.
   * @constructor
   */
  update() {
    return new FormDialogFactory("update");
  }
}
const formDialog = new FormDialogConstructors();
export { formDialog };