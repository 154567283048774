import { FormLayer } from './form-layer.js';
import { assertInInjectionContext, Injector, inject, runInInjectionContext } from '@angular/core';
class BaseFormLayerConfig {}
class FormLayerConfig extends BaseFormLayerConfig {
  constructor(controls, nullable) {
    super();
    this.controls = controls;
    this.nullable = nullable;
    this.errorValidators = [];
    this.warningValidators = [];
    this.postConfiguration = [];
  }
  /**
   * Add validators to the layer
   * @param validators
   */
  withErrors(...validators) {
    this.errorValidators = [...this.errorValidators, ...validators];
    return this;
  }
  /**
   * Add warning validators to the layer
   * @param validators
   */
  withWarnings(...validators) {
    this.warningValidators = [...this.warningValidators, ...validators];
    return this;
  }
  /**
   * Set a fallback value used when the layer is disabled
   * @param disabledDefault - The fallback to use
   */
  withDisabledDefault(disabledDefault) {
    this.disabledDefault = disabledDefault;
    return this;
  }
  /** Set the layer as disabled by default */
  disabled() {
    this.disabledByDefault = true;
    return this;
  }
  /**
   * Update the Layer after creation.
   * Can be used to configure autoDisable.
   * @param configure - The configuration function
   * @param injectionContext - The injection context to run the configuration in
   * - `true` - Use the current injection context
   * - `Injector` - Run in the context of the provided injector
   */
  configure(configure, injectionContext) {
    if (injectionContext) assertInInjectionContext(this.configure);
    const injector = !injectionContext ? void 0 : injectionContext instanceof Injector ? injectionContext : inject(Injector);
    if (injector) {
      configure = layer => runInInjectionContext(injector, () => configure(layer));
    }
    this.postConfiguration.push(configure);
    return this;
  }
  done() {
    return new FormLayer(this.controls, this.nullable, this.disabledDefault, this.disabledByDefault, this.errorValidators, this.warningValidators, this.postConfiguration);
  }
}
export { BaseFormLayerConfig, FormLayerConfig };