import { Observable, of } from 'rxjs';
import { FormNode } from './form-node.js';
import { InputTypes } from './anon-form-node.js';
class FormSelectNode extends FormNode {
  constructor(type, nullable, defaultValue, items, bindValue, initialValue, disabledDefault, errorValidators = [], warningValidators = [], options = {}) {
    super(type, nullable, defaultValue, initialValue, disabledDefault, errorValidators, warningValidators, options);
    this.selectOptions = options;
    this.items$ = items instanceof Observable ? items : of(items);
    this.bindValue = bindValue;
    this.bindLabel = options?.bindLabel;
    this.bindOption = options?.bindOption;
    this.clearable = options?.clearable ?? (type === InputTypes.SelectMany || nullable);
    this.hideWhenEmpty = options?.hideWhenEmpty ?? false;
  }
}
class FormMultiSelectNode extends FormSelectNode {
  constructor() {
    super(...arguments);
    this.multiple = true;
  }
  clone() {
    return new FormMultiSelectNode(this.type, this.nullable, this.defaultValue, this.items$, this.bindValue, this.initialValue, this.disabledDefault, this.errorValidators, this.warningValidators, {
      ...this.options,
      ...this.selectOptions
    });
  }
}
class FormSingleSelectNode extends FormSelectNode {
  constructor() {
    super(...arguments);
    this.multiple = false;
  }
  clone() {
    return new FormSingleSelectNode(this.type, this.nullable, this.defaultValue, this.items$, this.bindValue, this.initialValue, this.disabledDefault, this.errorValidators, this.warningValidators, {
      ...this.options,
      ...this.selectOptions
    });
  }
}
export { FormMultiSelectNode, FormSelectNode, FormSingleSelectNode };