import { mapObj } from '@juulsgaard/ts-tools';
import { AnonFormLayer } from './anon-form-layer.js';
import { computed, signal, untracked } from '@angular/core';
import { compareLists } from '../tools/helpers.js';
import { validationData, prependValidationPath, processFormValidators } from '../tools/form-validation.js';
import { FormNode } from './form-node.js';
import { FormList } from './form-list.js';
class FormLayer extends AnonFormLayer {
  constructor(controls, nullable = false, disabledDefaultValue, disabledByDefault = false, errorValidators = [], warningValidators = [], postConfiguration = []) {
    super(nullable, disabledByDefault);
    this.disabledDefaultValue = disabledDefaultValue;
    this.disabledByDefault = disabledByDefault;
    this.errorValidators = errorValidators;
    this.warningValidators = warningValidators;
    this.postConfiguration = postConfiguration;
    this.valid = computed(() => !this.hasError() && Object.values(this.controls()).every(x => x.valid()));
    this._isValid = computed(() => {
      const hasError = this.getErrors(this.value).next().done !== true;
      if (hasError) return false;
      for (let control of Object.values(this.controls())) {
        if (!control.isValid()) return false;
      }
      return true;
    });
    this._controls = signal(controls);
    this.controls = this._controls.asReadonly();
    this.rawValue = computed(() => this.getRawValue(x => x.rawValue));
    this.value = computed(() => this.getValue(x => x.value));
    this.debouncedRawValue = computed(() => this.getRawValue(x => x.debouncedRawValue));
    this.debouncedValue = computed(() => this.getValue(x => x.debouncedValue));
    this.resetValue = computed(() => this.processControls(x => x.resetValue()));
    this.changed = computed(() => Object.values(this.controls()).some(x => x.changed()));
    this.touched = computed(() => Object.values(this.controls()).some(x => x.touched()));
    this.errors = computed(() => Array.from(this.getErrors(this.debouncedValue)), {
      equal: compareLists
    });
    this.errorState = computed(() => {
      const errors = this.errors().map(msg => validationData(msg, this));
      this.iterateControls((control, key) => {
        for (let error of control.errorState()) {
          errors.push(prependValidationPath(error, key));
        }
      });
      return errors;
    });
    this.warnings = computed(() => Array.from(this.getWarnings(this.debouncedValue)), {
      equal: compareLists
    });
    this.warningState = computed(() => {
      const warnings = this.warnings().map(msg => validationData(msg, this));
      this.iterateControls((control, key) => {
        for (let warning of control.warningState()) {
          warnings.push(prependValidationPath(warning, key));
        }
      });
      return warnings;
    });
    untracked(() => postConfiguration.forEach(f => f(this)));
  }
  getDisabledValue() {
    const value = this.disabledDefaultValue;
    if (value != null) return value;
    if (this.nullable) return value;
    return this.processControls(x => x.getDisabledValue());
  }
  getRawValue(getVal) {
    if (this.disabled()) return this.disabledDefaultValue;
    return this.processControls(x => getVal(x)());
  }
  getValue(getVal) {
    if (this.disabled()) return this.getDisabledValue();
    return this.processControls(x => getVal(x)());
  }
  *getErrors(value) {
    if (this.disabled()) return [];
    yield* processFormValidators(this.errorValidators, value());
  }
  *getWarnings(value) {
    if (this.disabled()) return [];
    yield* processFormValidators(this.warningValidators, value());
  }
  processControls(process) {
    const out = {};
    this.iterateControls((control, key) => {
      out[key] = process(control);
    });
    return out;
  }
  iterateControls(action) {
    const controls = this.controls();
    for (let key in controls) {
      const control = controls[key];
      if (!control) continue;
      action(control, key);
    }
  }
  //<editor-fold desc="Control Mutation">
  // /**
  //  * Remove a nullable control from the layer
  //  * @param name - The property key for the control
  //  */
  // removeControl<K extends Extract<keyof TControls, string>>(name: K) {
  //   const controls = {...this.controls()};
  //   const existing = controls[name];
  //   if (!existing) return;
  //   if (!existing.nullable) throw new Error("You can only remove nullable controls");
  //   delete controls[name];
  //   this._controls.set(controls);
  // }
  // /**
  //  * Add a control to the layer.
  //  * Throws an exception if a control already exists
  //  * @param name - The property key for the control
  //  * @param control - The control to add
  //  */
  // addControl<K extends Extract<keyof TControls, string>>(name: K, control: Required<TControls>[K]) {
  //   const controls = {...this.controls()};
  //   if (controls[name]) throw new Error(`A control with the name '${name}' already exists`);
  //   controls[name] = control;
  //   this._controls.set(controls);
  // }
  /**
   * Set a control in the layer (Will override existing)
   * @param name - The property key for the control
   * @param control - The control to add
   */
  setControl(name, control) {
    const controls = {
      ...this.controls()
    };
    controls[name] = control;
    this._controls.set(controls);
  }
  //</editor-fold>
  //<editor-fold desc="Value update">
  setValue(value) {
    untracked(() => {
      this.iterateControls((control, prop) => {
        const val = value[prop];
        if (control instanceof FormNode) {
          control.setValue(val);
          return;
        }
        if (control instanceof FormLayer) {
          control.setValue(val);
          return;
        }
        if (control instanceof FormList) {
          if (!Array.isArray(val)) return;
          control.setValue(val);
          return;
        }
      });
    });
  }
  patchValue(value) {
    if (value == null) return;
    untracked(() => {
      this.iterateControls((control, prop) => {
        if (!value.hasOwnProperty(prop)) return;
        const val = value[prop];
        if (control instanceof FormNode) {
          control.setValue(val);
          return;
        }
        if (control instanceof FormLayer) {
          control.patchValue(val);
          return;
        }
        if (control instanceof FormList) {
          if (!Array.isArray(val)) return;
          control.patchValue(val);
          return;
        }
      });
    });
  }
  reset(value) {
    const obj = value ?? {};
    untracked(() => {
      this.iterateControls((control, prop) => {
        const val = obj[prop];
        if (control instanceof FormNode) {
          control.reset(val);
          return;
        }
        if (control instanceof FormLayer) {
          control.reset(val);
          return;
        }
        if (control instanceof FormList) {
          if (val !== void 0 && !Array.isArray(val)) return;
          control.reset(val);
          return;
        }
      });
    });
    super.reset();
  }
  //</editor-fold>
  /**
   * Create a clone of the layer and all it's controls.
   * This does not clone over any values.
   */
  clone() {
    return new FormLayer(mapObj(untracked(this.controls), x => x.clone()), this.nullable, this.disabledDefaultValue, this.disabledByDefault, this.errorValidators, this.warningValidators, this.postConfiguration);
  }
  clear() {
    untracked(() => this.processControls(x => x.clear()));
  }
  markAsTouched() {
    untracked(() => this.processControls(x => x.markAsTouched()));
  }
  markAsUntouched() {
    untracked(() => this.processControls(x => x.markAsUntouched()));
  }
  rollback() {
    untracked(() => this.processControls(x => x.rollback()));
  }
  isValid() {
    return untracked(this._isValid);
  }
  getValidValue() {
    if (!this.isValid()) throw Error("The value is invalid");
    return untracked(this.value);
  }
  getValidValueOrDefault(defaultVal) {
    if (!this.isValid()) return defaultVal;
    return untracked(this.value);
  }
}
export { FormLayer };