import { isString } from '@juulsgaard/ts-tools';
function validationData(message, unit) {
  return {
    data: {
      message,
      unit
    },
    path: []
  };
}
function prependValidationPath(data, prefix) {
  return {
    data: data.data,
    path: [prefix, ...data.path]
  };
}
function* processFormValidators(validators, value) {
  if (validators.length <= 0) return;
  for (let validator of validators) {
    const result = validator(value);
    if (result == null) continue;
    if (isString(result)) {
      yield result;
      continue;
    }
    for (const error of result) {
      yield error;
    }
  }
}
export { prependValidationPath, processFormValidators, validationData };