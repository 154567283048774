import { FormNodeConfig } from './form-node-config.js';
import { FormSingleSelectNode, FormMultiSelectNode } from './form-select-node.js';
class FormSelectNodeConfig extends FormNodeConfig {
  /**
   * Create a FormSelect Config manually.
   * It is recommended to use the `Form.xxx` constructors for creating configurations.
   * @param type - The type of the input
   * @param defaultValue - The default value of the input.
   * This input is used as a fallback for missing values.
   * @param items - The items to show in the select
   * @param bindValue - A mapping for getting the value of the item
   * @param initialValue - The initial value of the input.
   * This is used for initial setup and resetting the input.
   * Defaults to defaultValue.
   * @param nullable - Whether the input is nullable
   * @param disabledDefault - Define a distinct default raw value for when the input is disabled
   */
  constructor(type, nullable, defaultValue, items, bindValue, initialValue, disabledDefault) {
    super(type, nullable, defaultValue, initialValue, disabledDefault);
    this.items = items;
    this.bindValue = bindValue;
    this.clearable = true;
    this.hideWhenEmpty = false;
  }
  //<editor-fold desc="Configuration">
  /**
   * Define data bindings for the items
   * @param label - A binding for the display name of items
   * @param option - An optional binding for display names in the dropdown.
   * Defaults to the label binding
   */
  withBinds(label, option) {
    this.bindLabel = label;
    this.bindOption = option;
    return this;
  }
  /**
   * Disable input clearing for nullable inputs
   */
  noClear() {
    this.clearable = false;
    return this;
  }
  /**
   * Hide the input when it has no items to select
   */
  hideEmpty() {
    this.hideWhenEmpty = true;
    return this;
  }
  //</editor-fold>
  getSelectOptions() {
    return {
      bindLabel: this.bindLabel,
      bindOption: this.bindOption,
      clearable: this.clearable,
      hideWhenEmpty: this.hideWhenEmpty
    };
  }
}
class FormSingleSelectNodeConfig extends FormSelectNodeConfig {
  done() {
    return new FormSingleSelectNode(this.type, this.nullable, this.defaultValue, this.items, this.bindValue, this.initialValue, this.disabledDefault, this.errorValidators, this.warningValidators, {
      ...this.getOptions(),
      ...this.getSelectOptions()
    });
  }
}
class FormMultiSelectNodeConfig extends FormSelectNodeConfig {
  done() {
    return new FormMultiSelectNode(this.type, this.nullable, this.defaultValue, this.items, this.bindValue, this.initialValue, this.disabledDefault, this.errorValidators, this.warningValidators, {
      ...this.getOptions(),
      ...this.getSelectOptions()
    });
  }
}
export { FormMultiSelectNodeConfig, FormSelectNodeConfig, FormSingleSelectNodeConfig };