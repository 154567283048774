import { FormLayerConfig } from './form-layer-config.js';
import { FormRoot } from './form-root.js';
class FormRootConfig extends FormLayerConfig {
  constructor(controls) {
    super(controls, false);
  }
  done() {
    return new FormRoot(this.controls, this.disabledDefault, this.disabledByDefault, this.errorValidators, this.warningValidators);
  }
}
export { FormRootConfig };