import { formTemplateToControls, formTemplateToValueControls } from '../tools/templates.js';
import { FormConstants } from '../tools/constants.js';
import '@juulsgaard/ts-tools';
import '@angular/core';
import 'rxjs';
import '../forms/form-node.js';
import { FormRootConfig } from '../forms/form-root-config.js';
import { FormLayerConfig } from '../forms/form-layer-config.js';
import { FormListConfig } from '../forms/form-list-config.js';
import { FormNodeConfig } from '../forms/form-node-config.js';
import { InputTypes } from '../forms/anon-form-node.js';
import { Validators } from '../tools/validators.js';
import { FormSelectBuilder } from './form-select-builder.js';
import { parseOptions } from './constructor-tools.js';
class FormConstructors {
  constructor() {
    this.nullable = new FormNullableConstructors();
  }
  /**
   * Create a generic input with no type
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  generic(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, initialValue);
    return new FormNodeConfig(InputTypes.Generic, false, fallback, initialValue, disabled);
  }
  //<editor-fold desc="String Inputs">
  /**
   * Create a readonly input for storing Ids
   */
  id(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Text, false, fallback, initialValue, disabled).withLabel("Id").asReadonly();
  }
  /**
   * Create a text input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  text(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Text, false, fallback, initialValue, disabled);
  }
  /**
   * Create a GUID input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  guid(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_GUID);
    return new FormNodeConfig(InputTypes.Text, false, fallback, initialValue, disabled);
  }
  /**
   * Create a URL input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  url(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Url, false, fallback, initialValue, disabled).withErrors(Validators.url());
  }
  /**
   * Create a password input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  password(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Password, false, fallback, initialValue, disabled);
  }
  /**
   * Create a color input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  color(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Color, false, fallback, initialValue, disabled);
  }
  /**
   * Create a hex color input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  hexColor(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Color, false, fallback, initialValue, disabled).withErrors(Validators.hexColor());
  }
  /**
   * Create an email input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  email(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Email, false, fallback, initialValue, disabled).withErrors(Validators.email());
  }
  /**
   * Create a phone number input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  phone(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Phone, false, fallback, initialValue, disabled);
  }
  /**
   * Create a textfield input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  longText(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.LongText, false, fallback, initialValue, disabled);
  }
  /**
   * Create an HTML input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  html(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.HTML, false, fallback, initialValue, disabled);
  }
  /**
   * Create a search input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  search(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, "");
    return new FormNodeConfig(InputTypes.Search, false, fallback, initialValue, disabled);
  }
  //</editor-fold>
  //<editor-fold desc="Misc Inputs">
  /**
   * Create a number input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  number(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, 0);
    return new FormNodeConfig(InputTypes.Number, false, fallback, initialValue, disabled);
  }
  /**
   * Create a boolean input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  bool(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, false);
    return new FormNodeConfig(InputTypes.Bool, false, fallback, initialValue, disabled);
  }
  /**
   * Create a file input
   */
  file() {
    return new FormNodeConfig(InputTypes.File, false, FormConstants.NULL_FILE);
  }
  //</editor-fold>
  //<editor-fold desc="Date Inputs">
  /**
   * Create a date input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  date(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.Date, false, fallback, initialValue, disabled);
  }
  /**
   * Create a date and time input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  datetime(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.DateTime, false, fallback, initialValue, disabled);
  }
  /**
   * Create a time input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  time(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.Time, false, fallback, initialValue, disabled);
  }
  //</editor-fold>
  /**
   * Create a select endpoint
   * @param items - The items to select from
   */
  select(items) {
    return new FormSelectBuilder(items);
  }
  layer(template) {
    const controls = formTemplateToControls(template);
    return new FormLayerConfig(controls, false);
  }
  list(template) {
    const controls = formTemplateToControls(template);
    return new FormListConfig(controls, false);
  }
  root(template) {
    const controls = formTemplateToControls(template);
    return new FormRootConfig(controls);
  }
  guide() {
    return new FormGuideConstructors();
  }
}
class FormNullableConstructors {
  /**
   * Create a nullable generic input with no type
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  generic(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Generic, true, fallback, initialValue, disabled);
  }
  //<editor-fold desc="String Inputs">
  /**
   * Create a nullable readonly input for storing Ids
   */
  id(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Text, true, fallback, initialValue, disabled).withLabel("Id").asReadonly();
  }
  /**
   * Create a nullable text input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  text(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Text, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable GUID input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  guid(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Text, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable URL input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  url(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Url, true, fallback, initialValue, disabled).withErrors(Validators.url());
  }
  /**
   * Create a nullable password input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  password(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Password, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable color input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  color(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Color, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable hex color input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  hexColor(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Color, true, fallback, initialValue, disabled).withErrors(Validators.hexColor());
  }
  /**
   * Create a nullablen email input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  email(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Email, true, fallback, initialValue, disabled).withErrors(Validators.email());
  }
  /**
   * Create a nullable phone number input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  phone(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Phone, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable textfield input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  longText(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.LongText, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullablen HTML input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  html(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.HTML, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable search input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  search(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Search, true, fallback, initialValue, disabled);
  }
  //</editor-fold>
  //<editor-fold desc="Misc Inputs">
  /**
   * Create a nullable number input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  number(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Number, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable boolean input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  bool(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormNodeConfig(InputTypes.Bool, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable file input
   */
  file() {
    return new FormNodeConfig(InputTypes.File, true, void 0);
  }
  //</editor-fold>
  //<editor-fold desc="Date Inputs">
  /**
   * Create a nullable date input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  date(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.Date, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable date and time input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  datetime(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.DateTime, true, fallback, initialValue, disabled);
  }
  /**
   * Create a nullable time input
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  time(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, FormConstants.NULL_DATE);
    return new FormNodeConfig(InputTypes.Time, true, fallback, initialValue, disabled);
  }
  //</editor-fold>
  layer(template) {
    const controls = formTemplateToControls(template);
    return new FormLayerConfig(controls, true);
  }
  list(template) {
    const controls = formTemplateToControls(template);
    return new FormListConfig(controls, true);
  }
}
class FormGuideConstructors {
  /**
   * Define the form using subset of the guide template.
   * This sacrifices type conciseness for flexibility.
   * @param template - The template
   */
  partial(template) {
    const controls = formTemplateToValueControls(template);
    return new FormRootConfig(controls);
  }
  /**
   * Define the form using a loose template.
   * This sacrifices type conciseness for flexibility.
   * @param template - The template
   */
  modified(template) {
    const controls = formTemplateToValueControls(template);
    return new FormRootConfig(controls);
  }
}
const Form = new FormConstructors();
export { Form, FormConstructors, FormNullableConstructors };