import '@angular/core';
import 'rxjs';
import { FormRoot } from '../forms/form-root.js';
import '@juulsgaard/ts-tools';
import '../forms/form-node.js';
import { toList } from '../tools/helpers.js';
class FormRootConstructors {
  /**
   * Create an anonymously typed form
   * @param controls - The controls for the form
   * @param options - Options
   * @constructor
   */
  controls(controls, options) {
    return new FormRoot(controls, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
  /**
   * Create a form based on a type
   * @param controls - The controls matching the type
   * @param options - Options
   * @constructor
   */
  model(controls, options) {
    return new FormRoot(controls, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
}
const formRoot = new FormRootConstructors();
export { FormRootConstructors, formRoot };