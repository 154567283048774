import { FormList } from './form-list.js';
import { FormLayerConfig } from './form-layer-config.js';
import { assertInInjectionContext, Injector, inject, runInInjectionContext } from '@angular/core';
class BaseFormListConfig {}
class FormListConfig extends BaseFormListConfig {
  constructor(controls, nullable) {
    super();
    this.controls = controls;
    this.nullable = nullable;
    this.errorValidators = [];
    this.warningValidators = [];
    this.postConfiguration = [];
    this.layerConfig = new FormLayerConfig(controls, false);
  }
  /**
   * Set the default length of the list
   * @param length - The default length
   */
  withLength(length) {
    this.startLength = length;
    return this;
  }
  /**
   * Add validators to the list
   * @param validators
   */
  withErrors(...validators) {
    this.errorValidators = [...this.errorValidators, ...validators];
    return this;
  }
  /**
   * Add warning validators to the list
   * @param validators
   */
  withWarnings(...validators) {
    this.warningValidators = [...this.warningValidators, ...validators];
    return this;
  }
  /**
   * Set a fallback value used when the list is disabled
   * @param disabledDefault - The fallback to use
   */
  withDisabledDefault(disabledDefault) {
    this.disabledDefault = disabledDefault;
    return this;
  }
  /** Set the list as disabled by default */
  disabled() {
    this.disabledByDefault = true;
    return this;
  }
  /**
   * Configure the underlying layer control for the list
   * @param configure
   */
  layer(configure) {
    configure(this.layerConfig);
    return this;
  }
  /**
   * Update the List after creation.
   * Can be used to configure autoDisable.
   * @param configure - The configuration function
   * @param injectionContext - The injection context to run the configuration in
   * - `true` - Use the current injection context
   * - `Injector` - Run in the context of the provided injector
   */
  configure(configure, injectionContext) {
    if (injectionContext) assertInInjectionContext(this.configure);
    const injector = !injectionContext ? void 0 : injectionContext instanceof Injector ? injectionContext : inject(Injector);
    if (injector) {
      configure = list => runInInjectionContext(injector, () => configure(list));
    }
    this.postConfiguration.push(configure);
    return this;
  }
  done() {
    return new FormList(this.layerConfig.done(), this.nullable, this.startLength, this.disabledDefault, this.disabledByDefault, this.errorValidators, this.warningValidators, this.postConfiguration);
  }
}
export { BaseFormListConfig, FormListConfig };