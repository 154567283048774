import { FormNode } from './form-node.js';
class BaseFormNodeConfig {}
class FormNodeConfig extends BaseFormNodeConfig {
  /**
   * Create a Form Node manually.
   * It is recommended to use the `Form.xxx` constructors for configuring Form Nodes.
   * @param type - The type of the input
   * @param defaultValue - The default value of the input.
   * This input is used as a fallback for missing values.
   * @param initialValue - The initial value of the input.
   * This is used for initial setup and resetting the input.
   * Defaults to defaultValue.
   * @param nullable - Whether the input is nullable
   * @param disabledDefault - A default value for when the input is disabled
   */
  constructor(type, nullable, defaultValue, initialValue, disabledDefault) {
    super();
    this.type = type;
    this.nullable = nullable;
    this.defaultValue = defaultValue;
    this.initialValue = initialValue;
    this.disabledDefault = disabledDefault;
    this.errorValidators = [];
    this.warningValidators = [];
  }
  /**
   * Mark the input as required
   */
  required() {
    this.isRequired = true;
    return this;
  }
  /**
   * Add a label to the input
   * @param label
   */
  withLabel(label) {
    this.label = label;
    return this;
  }
  /**
   * Add HTML autocompletion to the input
   * @param autocomplete - The type of autocomplete
   */
  autocompleteAs(autocomplete) {
    this.autocomplete = autocomplete;
    return this;
  }
  /**
   * Add validators to the input
   * @param validators
   */
  withErrors(...validators) {
    this.errorValidators = [...this.errorValidators, ...validators];
    return this;
  }
  /**
   * Add warning validators to the input
   * @param validators
   */
  withWarnings(...validators) {
    this.warningValidators = [...this.warningValidators, ...validators];
    return this;
  }
  /**
   * Add a tooltip to the input.
   * This can be used to explain details about the input and it's use.
   * @param tooltip - The tooltip to show
   */
  withTooltip(tooltip) {
    this.tooltip = tooltip;
    return this;
  }
  /**
   * Show the input even when disabled.
   * By default, disabled inputs are hidden.
   */
  showDisabled() {
    this.showDisabledField = true;
    return this;
  }
  /**
   * Mark the input as read-only
   */
  asReadonly() {
    this.readonly = true;
    return this;
  }
  /**
   * Autofocus on the input
   */
  withFocus() {
    this.autoFocus = true;
    return this;
  }
  /**
   * Set a fallback value used when the input is disabled
   * @param disabledDefault - The fallback to use
   */
  withDisabledDefault(disabledDefault) {
    this.disabledDefault = disabledDefault;
    return this;
  }
  /** Set the node as disabled by default */
  disabled() {
    this.disabledByDefault = true;
    return this;
  }
  getOptions() {
    return {
      label: this.label,
      readonly: this.readonly,
      autocomplete: this.autocomplete,
      autoFocus: this.autoFocus,
      showDisabledField: this.showDisabledField,
      tooltip: this.tooltip,
      disabled: this.disabledByDefault,
      required: this.isRequired
    };
  }
  /**
   * Finalise the config and produce the input
   */
  done() {
    return new FormNode(this.type, this.nullable, this.defaultValue, this.initialValue, this.disabledDefault, this.errorValidators, this.warningValidators, this.getOptions());
  }
}
export { BaseFormNodeConfig, FormNodeConfig };