import '@angular/core';
import 'rxjs';
import '@juulsgaard/ts-tools';
import '../forms/form-node.js';
import { InputTypes } from '../forms/anon-form-node.js';
import { FormSingleSelectNodeConfig, FormMultiSelectNodeConfig } from '../forms/form-select-node-config.js';
import { parseOptions } from './constructor-tools.js';
class FormSelectBuilder {
  constructor(items) {
    this.items = items;
  }
  single(selection) {
    return new SingleFormSelectBuilder(this.items, selection ? selection : x => x);
  }
  multiple(selection) {
    return new MultiFormSelectBuilder(this.items, selection ? selection : x => x);
  }
}
class SingleFormSelectBuilder {
  constructor(items, map) {
    this.items = items;
    this.map = map;
  }
  /**
   * Make the input nullable
   * @param initialValue - The starting value of the input
   * @param options - Additional configuration
   */
  nullable(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options);
    return new FormSingleSelectNodeConfig(InputTypes.Select, true, fallback, this.items, this.map, initialValue, disabled);
  }
  /**
   * Make the input non-nullable
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  notNull(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, initialValue);
    return new FormSingleSelectNodeConfig(InputTypes.Select, false, fallback, this.items, this.map, initialValue, disabled);
  }
}
class MultiFormSelectBuilder {
  constructor(items, map) {
    this.items = items;
    this.map = map;
  }
  /**
   * Make the input non-nullable
   * @param initialValue - The starting value
   * @param options - Additional configuration
   */
  notNull(initialValue, options = {}) {
    const {
      fallback,
      disabled
    } = parseOptions(initialValue, options, []);
    return new FormMultiSelectNodeConfig(InputTypes.SelectMany, false, fallback, this.items, this.map, initialValue, disabled);
  }
}
export { FormSelectBuilder, MultiFormSelectBuilder, SingleFormSelectBuilder };