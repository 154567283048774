import '@angular/core';
import 'rxjs';
import { FormRoot } from '../forms/form-root.js';
import { FormLayer } from '../forms/form-layer.js';
import { FormNode } from '../forms/form-node.js';
import { FormList } from '../forms/form-list.js';
import { InputTypes } from '../forms/anon-form-node.js';
import { FormSelectNode, FormSingleSelectNode, FormMultiSelectNode } from '../forms/form-select-node.js';
function isFormNode(data) {
  return data instanceof FormNode;
}
function isFormSelectNode(data) {
  return data instanceof FormSelectNode;
}
function isFormSingleSelectNode(data) {
  return data instanceof FormSingleSelectNode;
}
function isFormMultiSelectNode(data) {
  return data instanceof FormMultiSelectNode;
}
function isFormLayer(data) {
  return data instanceof FormLayer;
}
function isFormList(data) {
  return data instanceof FormList;
}
function isFormRoot(data) {
  return data instanceof FormRoot;
}
function isStringNode(node) {
  switch (node.type) {
    case InputTypes.Text:
    case InputTypes.Url:
    case InputTypes.Password:
    case InputTypes.Color:
    case InputTypes.Email:
    case InputTypes.Phone:
    case InputTypes.LongText:
    case InputTypes.HTML:
    case InputTypes.Search:
      return true;
  }
  return false;
}
function isStringNodeStrict(node) {
  if (node.nullable) return false;
  return isStringNode(node);
}
function isNumberNode(node) {
  switch (node.type) {
    case InputTypes.Number:
      return true;
  }
  return false;
}
function isNumberNodeStrict(node) {
  if (node.nullable) return false;
  return isNumberNode(node);
}
function isBoolNode(node) {
  switch (node.type) {
    case InputTypes.Bool:
      return true;
  }
  return false;
}
function isDateNode(node) {
  switch (node.type) {
    case InputTypes.Date:
    case InputTypes.DateTime:
    case InputTypes.Time:
      return true;
  }
  return false;
}
function isDateNodeStrict(node) {
  if (node.nullable) return false;
  return isDateNode(node);
}
function isFileNode(node) {
  switch (node.type) {
    case InputTypes.File:
      return true;
  }
  return false;
}
export { isBoolNode, isDateNode, isDateNodeStrict, isFileNode, isFormLayer, isFormList, isFormMultiSelectNode, isFormNode, isFormRoot, isFormSelectNode, isFormSingleSelectNode, isNumberNode, isNumberNodeStrict, isStringNode, isStringNodeStrict };