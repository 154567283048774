import { FormPageFactory } from './form-page-factory.js';
class FormPageConstructors {
  edit() {
    return new FormPageFactory("update");
  }
  create() {
    return new FormPageFactory("create");
  }
}
const formPage = new FormPageConstructors();
export { formPage };