import { Observable, lastValueFrom } from 'rxjs';
import { ILoadingState } from '@juulsgaard/rxjs-tools';
import { signal } from '@angular/core';
import '@juulsgaard/ts-tools';
import '../forms/form-node.js';
import { InputTypes } from '../forms/anon-form-node.js';
import '../tools/constants.js';
import { formRoot } from '../constructors/form-root-constructors.js';
class BaseFormDialog {
  constructor(type, options, buttonText) {
    this._show = signal(false);
    /** Whether the dialog should be shown */
    this.show = this._show.asReadonly();
    this._working = signal(false);
    /** Whether the submission is currently in progress */
    this.working = this._working.asReadonly();
    this.createForm = type === "create";
    this.onSubmit = options.onSubmit;
    this.title = options.title;
    this.description = options.description;
    this.buttonText = buttonText ?? (this.createForm ? "Create" : "Save");
  }
  /**
   * Start the dialog.
   * This will reset and show the dialog.
   * @param reset - Optional reset data
   */
  start(reset) {
    this.form.reset(reset);
    this.form.markAsUntouched();
    this._show.set(true);
  }
  /**
   * Close the dialog
   */
  close() {
    this._show.set(false);
  }
  /**
   * Submit the form Dialog with the current values
   */
  async submit() {
    if (this.working()) return;
    if (!this.form.isValid()) return;
    this._working.set(true);
    try {
      const value = this.form.getValidValue();
      let result = this.onSubmit(value);
      if (result instanceof ILoadingState) {
        await result;
      } else if (result instanceof Observable) {
        await lastValueFrom(result);
      } else if (result instanceof Promise) {
        await result;
      }
      this._show.set(false);
    } finally {
      this._working.set(false);
    }
  }
}
class FormDialog extends BaseFormDialog {
  /**
   * Manually create a Form Dialog.
   * It is recommended to the `formDialog.create<T>()` or `formDialog.update<T>()` when creating a Form Dialog.
   * @param controls - The Form Template
   * @param type - The type of dialog
   * @param options - Settings
   * @param submitOnEnter
   * @param buttonText
   * @param errorValidators
   * @param warningValidators
   */
  constructor(controls, type, options, submitOnEnter, buttonText, errorValidators = [], warningValidators = []) {
    super(type, options, buttonText);
    this.form = formRoot.model(controls, {
      errors: errorValidators,
      warnings: warningValidators
    });
    this.controls = this.form.controls;
    this.value = this.form.value;
    this.valid = this.form.valid;
    this.errors = this.form.errors;
    this.errorState = this.form.errorState;
    this.warnings = this.form.warnings;
    this.warningState = this.form.warningState;
    this.canSubmit = this.createForm ? this.form.canCreate : this.form.canUpdate;
    this.submitOnEnter = submitOnEnter ?? this.shouldSubmitOnEnter();
  }
  shouldSubmitOnEnter() {
    const nodes = this.form.nodes().filter(x => !x.readonly);
    const count = nodes.length;
    if (count <= 0) return true;
    if (count > 1) return false;
    const type = nodes.at(0).type;
    switch (type) {
      case InputTypes.LongText:
      case InputTypes.HTML:
      case InputTypes.Select:
      case InputTypes.SelectMany:
      case InputTypes.Generic:
      case InputTypes.Search:
        return false;
      default:
        return true;
    }
  }
}
export { BaseFormDialog, FormDialog };