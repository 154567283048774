import { FormLayer } from '../forms/form-layer.js';
import { toList } from '../tools/helpers.js';
class FormLayerConstructors {
  constructor() {
    this.nullable = new FormLayerNullableConstructors();
  }
  /**
   * Create an anonymously typed layer
   * @param controls - The controls for the layer
   * @param options - Additional options
   * @constructor
   */
  controls(controls, options) {
    return new FormLayer(controls, false, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
  /**
   * Create a layer based on a type
   * @param controls - The controls matching the type
   * @param options - Additional options
   * @constructor
   */
  model(controls, options) {
    return new FormLayer(controls, false, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
}
class FormLayerNullableConstructors {
  /**
   * Create an anonymously typed layer
   * @param controls - The controls for the layer
   * @param options - Additional options
   * @constructor
   */
  controls(controls, options) {
    return new FormLayer(controls, true, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
  /**
   * Create a layer based on a type
   * @param controls - The controls matching the type
   * @param options - Additional options
   * @constructor
   */
  model(controls, options) {
    return new FormLayer(controls, true, options?.disabledFallback, options?.disabled, toList(options?.errors), toList(options?.warnings));
  }
}
const formLayer = new FormLayerConstructors();
export { FormLayerConstructors, FormLayerNullableConstructors, formLayer };