import { computed, signal, untracked } from '@angular/core';
import { validationData, prependValidationPath, processFormValidators } from '../tools/form-validation.js';
import { AnonFormList } from './anon-form-list.js';
import { compareLists } from '../tools/helpers.js';
class FormList extends AnonFormList {
  constructor(template, nullable, startLength = 0, disabledDefaultValue, disabledByDefault = false, errorValidators = [], warningValidators = [], postConfiguration = []) {
    super(nullable, disabledByDefault);
    this.template = template;
    this.startLength = startLength;
    this.disabledDefaultValue = disabledDefaultValue;
    this.disabledByDefault = disabledByDefault;
    this.errorValidators = errorValidators;
    this.warningValidators = warningValidators;
    this.postConfiguration = postConfiguration;
    this.valid = computed(() => !this.hasError() && this.controls().every(x => x.valid()));
    this._isValid = computed(() => {
      const hasError = this.getErrors(this.value).next().done !== true;
      if (hasError) return false;
      for (let control of this.controls()) {
        if (!control.isValid()) return false;
      }
      return true;
    });
    const initialControls = Array.from(Array(startLength)).map(() => this.template.clone());
    this._controls = signal(initialControls);
    this.controls = this._controls.asReadonly();
    this.rawValue = computed(() => this.getRawValue(x => x.rawValue));
    this.value = computed(() => this.getValue(x => x.value));
    this.debouncedRawValue = computed(() => this.getRawValue(x => x.debouncedRawValue));
    this.debouncedValue = computed(() => this.getValue(x => x.debouncedValue));
    this.resetValue = computed(() => this.controls().map(x => x.resetValue()));
    this.touched = computed(() => this.controls().some(x => x.touched()));
    this.changed = computed(() => this.controls().some(x => x.changed()));
    this.errors = computed(() => Array.from(this.getErrors(this.debouncedValue)), {
      equal: compareLists
    });
    this.errorState = computed(() => {
      const errors = this.errors().map(msg => validationData(msg, this));
      this.controls().forEach((control, i) => {
        for (let error of control.errorState()) {
          errors.push(prependValidationPath(error, `[${i}]`));
        }
      });
      return errors;
    });
    this.warnings = computed(() => Array.from(this.getWarnings(this.debouncedValue)), {
      equal: compareLists
    });
    this.warningState = computed(() => {
      const warnings = this.warnings().map(msg => validationData(msg, this));
      this.controls().forEach((control, i) => {
        for (let warning of control.warningState()) {
          warnings.push(prependValidationPath(warning, `[${i}]`));
        }
      });
      return warnings;
    });
    postConfiguration.forEach(f => f(this));
  }
  getRawValue(getVal) {
    if (this.disabled()) return this.disabledDefaultValue;
    return this.controls().map(x => getVal(x)());
  }
  getValue(getVal) {
    if (this.disabled()) return this.getDisabledValue();
    return this.controls().map(x => getVal(x)());
  }
  *getErrors(value) {
    if (this.disabled()) return [];
    yield* processFormValidators(this.errorValidators, value());
  }
  *getWarnings(value) {
    if (this.disabled()) return [];
    yield* processFormValidators(this.warningValidators, value());
  }
  //<editor-fold desc="Helpers">
  scaleToSize(size) {
    size = Math.max(0, size);
    const controls = untracked(this.controls);
    if (controls.length === size) return false;
    if (size === 0) {
      return this.clear();
    }
    if (size < controls.length) {
      this._controls.set(controls.slice(0, size));
      return true;
    }
    if (size > controls.length) {
      const diff = size - controls.length;
      this._controls.set([...controls, ...Array.from(Array(diff)).map(() => this.template.clone())]);
      return true;
    }
    return false;
  }
  //</editor-fold>
  clear() {
    if (untracked(this.controls).length <= 0) return false;
    this._controls.set([]);
    return true;
  }
  reset(values) {
    if (values == null) {
      this.clear();
      super.reset();
      return;
    }
    this.scaleToSize(values.length);
    const controls = untracked(this.controls);
    for (let i = 0; i < controls.length && i < values.length; i++) {
      controls[i]?.reset(values[i]);
    }
    super.reset();
  }
  patchValue(values) {
    if (values == null) return;
    this.scaleToSize(values.length);
    const controls = untracked(this.controls);
    for (let i = 0; i < controls.length && i < values.length; i++) {
      controls[i]?.patchValue(values[i]);
    }
  }
  setValue(values) {
    this.scaleToSize(values.length);
    const controls = untracked(this.controls);
    for (let i = 0; i < controls.length && i < values.length; i++) {
      controls[i]?.patchValue(values[i]);
    }
  }
  //<editor-fold desc="Mutations">
  /**
   * Add a form layer to the end of the list
   * @param layers - The layers to add
   */
  addLayers(...layers) {
    this._controls.update(x => [...x, ...layers]);
    return layers;
  }
  setLayers(layers) {
    this._controls.set([...layers]);
    return layers;
  }
  /**
   * Add a value to the end of the list.
   * The value is converted to a Form Layer and appended.
   * @param value - The value to add
   */
  addElement(value) {
    const layer = this.template.clone();
    layer.reset(value);
    this.addLayers(layer);
    return layer;
  }
  /**
   * Add a value to the end of the list.
   * The value is converted to a Form Layer and appended.
   * @param values - The values to add
   */
  appendElements(...values) {
    return this.addLayers(...values.map(x => {
      const layer = this.template.clone();
      layer.reset(x);
      return layer;
    }));
  }
  /**
   * Update the value of the first match in the list.
   * If no match is found, add the item.
   * @param filter - The search filter
   * @param value - The value to update with
   */
  setElement(filter, value) {
    const control = untracked(this.controls).find(x => filter(untracked(x.value)));
    if (!control) return this.addElement(value);
    control.patchValue(value);
    return control;
  }
  /**
   * Update the value of the first match in the list
   * @param filter - The search filter
   * @param value - The value to update with
   */
  updateElement(filter, value) {
    const control = untracked(this.controls).find(x => filter(untracked(x.value)));
    if (!control) return null;
    control.patchValue(value);
    return control;
  }
  /**
   * Toggle the presence of an item based on the filter.
   * If a match is found it will be removed.
   * If no match is found the value will be added.
   * @param filter - The search filter
   * @param value - The value to add if applicable
   */
  toggleElement(filter, value) {
    const removed = this.removeElement(filter);
    if (!removed) return this.addElement(value);
    return null;
  }
  /**
   * Remove the first item matching the predicate
   * @param filter - The match predicate
   */
  removeElement(filter) {
    const index = untracked(this.controls).findIndex(x => filter(untracked(x.value)));
    return this.removeAt(index);
  }
  /**
   * Remove the given form layer
   * @param layer - The layer to remove
   */
  remove(layer) {
    const index = untracked(this.controls).findIndex(x => x === layer);
    return this.removeAt(index);
  }
  /**
   * Remove a layer at a specified index
   * @param index - The index at which to remove the item
   */
  removeAt(index) {
    if (index < 0) return void 0;
    let controls = untracked(this.controls);
    if (index >= controls.length) return void 0;
    controls = [...controls];
    const removed = controls.splice(index, 1);
    this._controls.set(controls);
    return removed[0];
  }
  //</editor-fold>
  //<editor-fold desc="Move Actions">
  /**
   * Move an item the list based on the Material CDK payload
   * @param data - The CDK move data
   */
  moveCdkElement(data) {
    this.moveElement(data.previousIndex, data.currentIndex);
  }
  /**
   * Move an element in the list
   * @param oldIndex - The Index of the element to move
   * @param newIndex - The target index for the element
   */
  moveElement(oldIndex, newIndex) {
    if (newIndex < 0) return false;
    const controls = [...untracked(this.controls)];
    if (newIndex >= controls.length) return false;
    const removed = controls.splice(oldIndex, 1);
    if (removed.length <= 0) return false;
    controls.splice(newIndex, 0, ...removed);
    this._controls.set(controls);
    return true;
  }
  //</editor-fold>
  /**
   * Clone the list based on its configuration.
   * No values are moved over.
   */
  clone() {
    return new FormList(this.template.clone(), this.nullable, this.startLength, this.disabledDefaultValue, this.disabledByDefault, this.errorValidators, this.warningValidators, this.postConfiguration);
  }
  getDisabledValue() {
    const value = this.disabledDefaultValue;
    if (value != null) return value;
    if (this.nullable) return value;
    return this.controls().map(x => x.getDisabledValue());
  }
  markAsTouched() {
    untracked(this.controls).forEach(x => x.markAsTouched());
  }
  markAsUntouched() {
    untracked(this.controls).forEach(x => x.markAsUntouched());
  }
  rollback() {
    untracked(this.controls).forEach(x => x.rollback());
  }
  isValid() {
    return untracked(this._isValid);
  }
  getValidValue() {
    if (!this.isValid()) throw Error("The value is invalid");
    return untracked(this.value);
  }
  getValidValueOrDefault(defaultVal) {
    if (!this.isValid()) return defaultVal;
    return untracked(this.value);
  }
}
export { FormList };