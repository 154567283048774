import { formTemplateToControls, formTemplateToValueControls } from '../tools/templates.js';
import { FormPageConfig } from './form-page-config.js';
class FormPageFactory {
  constructor(type) {
    this.type = type;
  }
  /**
   * Define the page form using a strict template with keys omitted
   * @param template - The template
   */
  withOmitted(template) {
    return new FormPageConfig(this.type, formTemplateToControls(template));
  }
  /**
   * Define the page form using a strict template
   * @param template - The template
   */
  withForm(template) {
    return new FormPageConfig(this.type, formTemplateToControls(template));
  }
  /**
   * Define the page form using subset of the guide template.
   * This sacrifices type conciseness for flexibility.
   * @param template - The template
   */
  withPartialForm(template) {
    return new FormPageConfig(this.type, formTemplateToValueControls(template));
  }
  /**
   * Define the page form using a loose template.
   * This sacrifices type conciseness for flexibility.
   * @param template - The template
   */
  withAltForm(template) {
    return new FormPageConfig(this.type, formTemplateToValueControls(template));
  }
  /**
   * Define the form controls for the Page strictly based on the type
   * @param controls - The controls
   */
  withControls(controls) {
    return new FormPageConfig(this.type, controls);
  }
}
export { FormPageFactory };